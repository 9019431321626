<template>
  <!-- Index -->
  <KycContainer @submit="next" :isMobile="isMobileDevice" v-if="step === AVAILABLE_STEPS.INDEX">
    <template slot="title">
      {{$t('kyc.choose_your_country_of_residence')}}
    </template>

    <div class="flex flex-col gap-s12">
      <div class="country-button border-border-active-nav subheadline-medium">
        <span
          data-cy="text-current-country"
          data-testid="text-current-country">{{ selectText }}</span>
        <country-flag :country="selectedCountry" size="big" rounded/>
      </div>

      <button
        data-cy="button-all-countries"
        data-testid="button-all-countries"
        class="country-button subheadline-medium"
        @click="openSelector">
        <span>{{$t('kyc.all_other_countries')}}</span>
        <IconWorldV2/>
      </button>
    </div>

    <template slot="actions">
      <ButtonV2
        data-cy="button-next"
        data-testid="button-next"
        :label="$tc('kyc.next')"
        submit
        wide
      />
    </template>
  </KycContainer>

  <!-- Select a country -->
  <KycCountrySelector v-else-if="step === AVAILABLE_STEPS.SELECTOR" @selected="selectCountry" :current-country="selectedCountry"/>

  <!-- Country not available -->
  <KycCountryUnavailable v-else-if="step === AVAILABLE_STEPS.UNAVAILABLE" @chooseAnother="openSelector"/>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import KycCountrySelector from '@/modules/user/Modals/KycCountry/KycCountrySelector';
import KycCountryUnavailable from '@/modules/user/Modals/KycCountry/KycCountryUnavailable';
import { KycContainer } from '@/components/kyc';
import { IconWorldV2 } from '@/assets/icons';
import countries from '@/utils/countries';
import AVAILABLE_KYC_COUNTRIES from '@/graphql/queries/AvailableKycCountries.gql';
import {mapActions} from 'vuex';

export const AVAILABLE_STEPS = {
  INDEX: 'index',
  SELECTOR: 'selector',
  UNAVAILABLE: 'unavailable'
};

export default {
  name: 'KycCountry',
  components: {
    ButtonV2,
    KycContainer,
    KycCountrySelector,
    KycCountryUnavailable,
    IconWorldV2,
  },

  props: {
    kycForm: Object,
    patchProfile: Boolean,
  },

  computed: {
    selectText(){
      return countries.find(item => item.code === this.selectedCountry).name;
    },
  },

  data() {
    return {
      availableKycCountries: [],
      AVAILABLE_STEPS,
      countries,
      selectedCountry: 'US',
      step: 'index',
    };
  },

  async mounted() {
    try {
      const response = await this.$apollo.query({
        query: AVAILABLE_KYC_COUNTRIES,
      });

      await this.loadUserInfo();

      await this.$store.dispatch('events/track', {
        event: 'KYC_COUNTRY_VIEWED',
      });
      this.availableKycCountries = response.data.available_kyc_countries;
    } catch (err) {
      await this.showError(err);
    }
  },

  methods: {
    ...mapActions('user', ['loadUserInfo', 'updateCountry']),

    next() {
      const isValidCountry = this.availableKycCountries.includes(this.selectedCountry);

      if (isValidCountry) {
        this.updateCountry({ addressCountry: this.selectedCountry });
      } else {
        this.step = this.AVAILABLE_STEPS.UNAVAILABLE;
      }
    },

    openSelector() {
      this.step = this.AVAILABLE_STEPS.SELECTOR;
    },

    selectCountry(newValue) {
      this.selectedCountry = newValue;
      this.step = this.AVAILABLE_STEPS.INDEX;
    }
  }
};
</script>

<style scoped>
  .country-button {
    @apply
      cursor-pointer
      flex
      items-center
      justify-between
      gap-s12
      bg-background-primary
      border
      p-s16
      rounded-16
      w-full;
    height: 80px;
  }
</style>
