<template>
  <KycContainer :is-mobile="isMobileDevice" padding="p-0" class="mt-s16">
    <div class="flex w-full">
      <CustomInput 
      testId="country-search"
      initialFocus @input="changeCountrySearch" :value="countrySearch" class="w-full">
        <template slot="icon">
          <IconSearch class="w-s20 h-s20 text-text-body fill-current cursor-pointer" />
        </template>
      </CustomInput>
    </div>

    <h4 
      data-testid="current-country"
      class="country-option subheadline-medium">
      <country-flag :country="currentCountry" size="big" rounded/>
      <span class="ml-s8">{{ selectText }}</span>
    </h4>

    <div data-testid="country-list" class="flex flex-col overflow-y-scroll h-full">
      <template v-for="(results, letter) in countriesFilteredByFirstLetter">
        <p :key="letter" class="bg-background-primary py-s16 px-s20 subheadline-medium">
          {{ letter }}
        </p>
        <template v-for="item in results">
          <button
            class="country-option subheadline-medium"
            type="button"
            :key="item.iso2"
            @click="selectCountry(item.code)"
          >
            <country-flag :country="item.code" size="big" rounded/>
            <span class="ml-s8">{{ item.name }}</span>
          </button>
        </template>
      </template>
    </div>
  </KycContainer>
</template>

<script>
import { KycContainer } from '@/components/kyc';
import { CustomInput } from '@/components/misc';
import { IconSearch } from '@/assets/icons';
import countries from '@/utils/countries.json';

export default {
  name: 'KycCountrySelector',
  components: {
    CustomInput,
    IconSearch,
    KycContainer,
  },

  data() {
    return {
      countrySearch: '',
    };
  },

  computed: {
    filteredCountryList(){
      const lowerCaseValue = String(this.countrySearch).toLowerCase();
      if (this.countrySearch) {
        return countries.filter(item => String(item.name).toLowerCase().includes(lowerCaseValue));
      }
      return countries;
    },

    countriesFilteredByFirstLetter(){
      const sorted = this.sortArrayOnProperty({
        array: this.filteredCountryList,
        property: 'name'
      });

      return sorted.reduce(function (acc, obj) {
        let key = obj['name'].split('')[0];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

    },

    selectText(){
      return countries.find(item => item.code === this.currentCountry).name;
    }
  },

  methods: {
    changeCountrySearch(newValue){
      this.countrySearch = newValue;
    },

    selectCountry(newValue) {
      this.$emit('selected', newValue);
    }
  },

  props: {
    currentCountry: {
      type: String,
      default: 'US',
    }
  }
};
</script>

<style scoped>
  .country-option {
    @apply flex gap-s12 items-center border-b py-s16 px-s20 border-border text-left;
  }
</style>
