<template>
  <KycContainer 
    element="div"
    padding="p-0"
    :class="containerClasses"
    :isMobile="isMobileDevice"
  >
    <template slot="title">
      <span class="p-s20">
        {{$t('kyc.country_unavailable')}}
      </span>
    </template>

    <ImageDisconnectCablesDark v-if="currentTheme === 'dark'" width="100%"/>
    <ImageDisconnectCablesLight v-else width="100%"/>

    <div class="text-container p-s20">
      {{$t('kyc.we_re_sorry_country_unavailable')}}
    </div>

    <template slot="actions">
      <div class="flex flex-col gap-s16 w-full p-s20">
        <ButtonV2
          @onClick="goBackHome"
          :label="$tc('kyc.back_home')"
          testId="btn-backHome"
          wide
        />

        <ButtonV2
          version="secondary"
          @onClick="chooseAnother"
          :label="$tc('kyc.choose_another_country')"
          testId="btn-choseAnotherCountry"
          wide
        />
      </div>
    </template>
  </KycContainer>
</template>

<script>
import { ImageDisconnectCablesDark, ImageDisconnectCablesLight } from '@/assets/img';
import { KycContainer } from '@/components/kyc';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'KycCountryUnavailable',
  components: {
    ButtonV2,
    ImageDisconnectCablesDark,
    ImageDisconnectCablesLight,
    KycContainer,
  },

  props: {
    kycForm: Object,
    countryCode: String,
  },

  computed: {
    containerClasses(){
      return {
        'kyc-container--desktop overflow-hidden': !this.isMobileDevice,
        'kyc-container--mobile': this.isMobileDevice
      };
    },
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'KYC_COUNTRY_UNAVAILABLE_VIEWED',
      variables: {
        country_code: this.countryCode,
      },
    });
  },

  methods: {
    goBackHome() {
      this.hideModal();
      this.goToInternalPageGlobal('/home');
    },

    chooseAnother() {
      this.$emit('chooseAnother');
    },
  }
};
</script>

<style scoped>
.kyc-container--desktop {
  height: 700px;
  width: 450px;
}
.kyc-container--mobile {
  height: 100%;
}
</style>
